<template>
  <div class="user">
      <div class="line_one">
        <el-form inline :model="queryInfo" label-width="80px" ref="form">
          <el-form-item label="需求状态:">
            <el-button :class="tabIndex1===''?'active':''" style="margin-left:10px" @click="searchClick('',1)" >全部</el-button>
            <el-button :class="tabIndex1==='待开始'?'active':''" @click="searchClick('待开始',1)">待开始</el-button>
            <el-button :class="tabIndex1==='进行中'?'active':''" @click="searchClick('进行中',1)">进行中</el-button>
            <el-button :class="tabIndex1==='已结束'?'active':''" @click="searchClick('已结束',1)">已结束</el-button>
          </el-form-item>
          <el-form-item label="项目名称:">
            <el-input v-model="queryInfo.projectName" placeholder="请输入项目名称" clearable />
          </el-form-item>
          <el-form-item label="项目时间:">
            <el-date-picker
              v-model="timeRanger"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '00:00:00']"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width:400px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="reset">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search()">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="line_two">
        <div class="buttonArea">
          <div class="add" @click="goAdd()">
            <i class="el-icon-plus"></i>
            新增项目
          </div>
        </div>
        <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column type="index" label="编号"  width="80" align="center" sortable/>
        <el-table-column prop="projectName" label="项目名称"  width="120" align="center"/>
        <el-table-column label="项目状态" width="150" align="center"  prop="projectState" >
         <template v-slot='{ row }'>
            <div class="projectStatus" v-if="row.projectState == '待开始' ">待开始</div>
            <div class="projectStatus" v-if="row.projectState == '已结束' ">已结束</div>
            <div class="projectStatus colorOne"  v-if="row.projectState == '进行中' ">进行中</div>
         </template>
        </el-table-column>
         <el-table-column label="参与会员" prop="participationMember" align="center" width="100"></el-table-column>
         <el-table-column label="居间人" prop="intermediary" align="center" width="100"/>
         <el-table-column label="运营专员" prop="userName" align="center" width="100"/>
         <el-table-column label="地点" prop="userName" align="center" width="180">
          <template v-slot='{ row }'>
            <div>{{row.country}}-{{row.province}}-{{row.city}}</div>
         </template>
        </el-table-column>
         <el-table-column label="项目开始时间" prop="projectStartTime" align="center" width="160" sortable/>
         <el-table-column label="项目结束时间" prop="projectEndTime" align="center" width="160" sortable/>
        <el-table-column label="项目描述" align="center" width="300px">
          <template v-slot='{ row }'>
            <div class="textFlow1">
              <textFlow :info="row.projectIntro" :lineClamp='2' :hiddenBtn="true" ></textFlow>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" >
          <template v-slot="{ row }">
            <div class="maker">
              <div class="edit" @click="goEdit(row.id)">编辑</div>
              <div class="edit" @click="goDetail(row.id)">查看详情</div>
              <div class="delete" @click="deleteOne(row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>


<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'
import { getProjectList, deleteProject } from "@/api/object.js"
const defaultQueryInfo = Object.freeze({
  current: 1,
  size: 10,
  state:'',
  id:0
})
export default {
  components: { textFlow },
  mixins: [pagination],
  data () {
    return {
      dataUserList: [{}],
      timeRanger: [],
      tabIndex1:'',
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo }
    }
  },
  created() {
    this.$nextTick(()=>{
      this.search()
    })
  },
  methods: {
    async deleteOne(id){
      this.$confirm(`确认删除所选项目吗 ?`, {
          type: 'warning'
      }).then(async() => {
        const { data: res } = await deleteProject({id:id})
        if (res.resultCode === 200) {
          this.$message.success(`删除成功`)
          this.search()
        } else {
          this.$message.error('删除失败，请重试。')
        }
        })
        .catch(() => {})
    },
    //按钮选中css
    searchClick(index) {
      this.tabIndex1 = index
      this.queryInfo.state = this.tabIndex1
      this.search()
    },
    async search() {
      if(this.timeRanger){
        this.queryInfo.projectStartTime = this.timeRanger[0]
        this.queryInfo.projectEndTime = this.timeRanger[1]
      }
      this.loading = true
      const { data: res } = await getProjectList(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    goAdd() {
      this.$router.push({
        path: `/objectManagement/addObject`,
      })
    },
    goEdit(id) {
      this.$router.push({
        path: `/objectManagement/addObject?id=${id}`,
      })
    },
    goDetail(id) {
      this.$router.push({
        path: `/objectManagement/objectDetail?id=${id}`,
      })
    },
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
        state:'',
        id: 0
      }
      this.timeRanger = []
      this.tabIndex1 = '',
      this.search()
    },
  },
}
</script> 

<style scoped lang="less">
.textFlow1{
  display: flex;
  justify-content: center;
}
.htmlTip{
  /deep/ p{
    width: 100%;
    color: #333333;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  /deep/ img{
    width: 100px;
    height: 100px;
  }
  
}
.projectStatus{
  margin: 0px auto;
  width: 70px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  background: #EAEAEA;
  color: #333333;
}
.colorOne{
  background: #CFD4FF;
  color:#7C71E1;
  cursor: pointer;
}
.el-button--primary{
  background: #7C71E1;
}
.el-button:hover,
.el-button:active {
  background: #7C71E1;
  color: #ffffff;
}
.active{
  background: #7C71E1;
  color: #ffffff;
  border: none;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_one{
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    padding: 20px 0px 0px 20px;
  } 
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        background: #7C71E1;
        color: #ffffff;
      }
      .add:hover{
        opacity: 0.7;
      }
    }
    .maker{
      display: flex;
      justify-content: center;
      font-size: 14px;
      div{
        cursor: pointer;
      }
      .delete{
        margin-left: 10px;
        color: #FF2828;
      }
      .edit{
        color: #7C71E1;
        margin-left: 10px;
      }
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}
</style>
